import { useRouter } from 'next/router';
import classNames from 'classnames';

import AppLink from '../AppLink';

import styles from './AppHeaderSubmenu.module.scss';

export default function AppHeaderSubmenu({ items, handleMenuClick }) {
  const { query } = useRouter();

  return (
    <div className={styles.AppHeaderSubmenu}>
      <ul className={styles.AppHeaderSubmenu__List}>
        {items.map((child, childIndex) => (
          <li
            key={childIndex}
            onClick={handleMenuClick}
            itemProp="name"
            className={classNames(styles.AppHeaderSubmenu__Item, {
              [ styles[ 'AppHeaderSubmenu__Item--Active' ] ]: child.child_slug === query.child,
            })}>
            <AppLink
              itemProp="url"
              ariaLabel={child.title}
              title={child.title}
              href={`/${child.page_slug}` || child.page_slug || child.redirect}
              target="_self">
              {child.title}
            </AppLink>
          </li>
        ))}
      </ul>
    </div>
  );
}
