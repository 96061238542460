import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';

import { useAppStateContext } from '../../hooks/context';
import { normalizeServerUrl } from '../../utils/url';
import AppContainer from '../AppContainer/AppContainer';
import AppLink from '../AppLink';
import AppImage from '../AppImage';
import AppButton from '../AppButton/AppButton';

import AppHeaderRespMenu from './AppHeaderRespMenu';
import AppHeaderNav from './AppHeaderNav';
import styles from './AppHeader.module.scss';

const LOCALES_MAP = {
  ka: 'ge',
  en: 'en',
};

export default function AppHeader({ localesUrls }) {
  const { menu } = useAppStateContext().context;

  const { locales, locale: activeLocale, asPath, query } = useRouter();

  const nextLang = locales.find((element) => element !== activeLocale);

  const pageLanguageLinks = (locale) => localesUrls?.find((x) => x.lang === LOCALES_MAP[ locale ])?.url;

  const createPath = (path, locale) => {
    const _locale = locale === process.env.DEFAULT_LOCALE ? '' : normalizeServerUrl(locale);

    return `${_locale}${normalizeServerUrl(pageLanguageLinks(locale) || '/')}`;
  };

  const [activeMenu, setActiveMenu] = useState(null);

  // header menu
  const headerMenu = menu?.data?.filter((item) => item.position === 'top' && item.item_id !== 17);
  // header contact button
  const contact = menu?.data?.filter((item) => item.position === 'top' && item.item_id === 17);

  useEffect(() => {
    activeLocale === 'ka'
      ? (document.body.dataset.lang = 'font_ge')
      : document.body.removeAttribute('data-lang');
    setActiveMenu(query?.parent);
  }, [query]);

  return (
    <header className={styles.AppHeader}>
      <AppHeaderRespMenu
        asPath={asPath}
        nextLang={nextLang}
        createPath={createPath}
        burgerMenuList={headerMenu}
        burgerContact={contact}
        activeMenu={activeMenu}
      />

      <div className={styles.AppHeader__Content}>
        <AppContainer>
          <div className={styles.AppHeader__Wrapper}>
            <div className={styles.AppHeader__Logo}>
              <AppLink ariaLabel={'Artmedia'} title={'Artmedia'} href="/">
                <AppImage src="/images/logo.svg" width="138" height="28" layout="fixed" />
              </AppLink>
            </div>
            <div className={styles.AppHeader__Wrapper__middle}>
              <AppHeaderNav headerMenu={headerMenu} activeMenu={activeMenu} />
            </div>
            <div className={styles.AppHeader__Wrapper__right}>
              <div className={styles.AppHeader__LanguageSwitcher}>
                <a href={createPath(asPath, nextLang)}>
                  <AppButton isHeader square transparent>
                    {nextLang.toUpperCase()}
                  </AppButton>
                </a>
              </div>
              <div className={styles.AppHeader__Contact}>
                {contact?.map((item, index) => (
                  <AppLink ariaLabel={item.title} key={index} href={item?.page_slug}>
                    {item.title}
                  </AppLink>
                ))}
              </div>
            </div>
          </div>
        </AppContainer>
      </div>
    </header>
  );
}
