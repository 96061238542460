import React from 'react';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';

import AppButton from '../AppButton/AppButton';
import AppLink from '../AppLink';
import AppImage from '../AppImage';

import styles from './AppHeaderRespMenu.module.scss';

export default function AppHeaderRespMenu({
  asPath,
  nextLang,
  createPath,
  burgerMenuList,
  burgerContact,
  activeMenu,
}) {
  const { query } = useRouter();
  const [burgerMenu, setBurgerMenu] = useState(false);
  const [openMenuIndex, setOpenMenuIndex] = useState(null);

  const menuVisibility = () => {
    setBurgerMenu(!burgerMenu);
  };

  const hideMenu = () => {
    setBurgerMenu(false);
  };

  useEffect(() => {
    const body = document.querySelector('body');

    if (burgerMenu) {
      body.style.overflow = 'hidden';
    } else {
      body.style.overflow = 'visible';
    }
  }, [burgerMenu]);

  const handleMenuClick = (index) => {
    setOpenMenuIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <>
      <div className={styles.AppHeader__Burger__Menu}>
        <div
          className={classNames(styles.AppHeader__Burger__Menu__Wrapper, {
            // eslint-disable-next-line
            [styles.Open]: burgerMenu,
          })}
          onClick={menuVisibility}>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>

      <div
        className={classNames(styles.AppHeader__Resp, {
          // eslint-disable-next-line
          [styles.Visible]: burgerMenu,
        })}>
        <div className={styles.AppHeader__Resp__Wrapper}>
          <div className={styles.AppHeader__Resp__Wrapper__Head}>
            <div className={styles.AppHeader__Resp__Wrapper__Head__LanguageSwitcher}>
              <a href={createPath(asPath, nextLang)} onClick={hideMenu}>
                <AppButton isHeader square transparent>
                  {nextLang.toUpperCase()}
                </AppButton>
              </a>
            </div>
            <div className={styles.AppHeader__Logo}>
              <AppLink ariaLabel={'Artmedia'} title={'Artmedia'}>
                <AppImage src="/images/logo.svg" width="138" height="28" layout="fixed" />
              </AppLink>
            </div>
          </div>
          <div className={styles.AppHeader__Resp__Wrapper__Content}>
            <nav>
              <ul>
                {burgerMenuList?.map((el, index) => (
                  <li
                    key={index}
                    className={el.page_slug === activeMenu ? styles.AppHeader__Resp__Active : ''}>
                    {el.children.length > 0 ? (
                      <>
                        <span
                          className={styles.AppHeaderResp__Item}
                          onClick={() => handleMenuClick(index)}>
                          <AppLink
                            href={el.redirect || el.page_slug}
                            onClick={hideMenu}
                            title={el.title}
                            ariaLabel={el.title}>
                            {el.title}
                          </AppLink>
                          {openMenuIndex === index ? (
                            <span className={styles.AppHeaderResp__Icon}>
                              <ChevronUp />
                            </span>
                          ) : (
                            <span className={styles.AppHeaderResp__Icon}>
                              <ChevronDown />
                            </span>
                          )}
                        </span>
                        {openMenuIndex === index && (
                          <ul className={styles.AppHeaderResp__List}>
                            {el.children.map((child, childIndex) => (
                              <li
                                key={childIndex}
                                className={classNames(styles.AppHeaderResp__ListItem, {
                                  [ styles[ 'AppHeaderResp__ListItem--Active' ] ]:
                                    child.child_slug === query.child,
                                })}>
                                <AppLink
                                  onClick={hideMenu}
                                  target="_self"
                                  ariaLabel={child.title}
                                  title={child.title}
                                  href={child.redirect || `/${child.page_slug}`}>
                                  {child.title}
                                </AppLink>
                              </li>
                            ))}
                          </ul>
                        )}
                      </>
                    ) : (
                      <AppLink
                        href={el.redirect || el.page_slug}
                        onClick={hideMenu}
                        title={el.title}
                        ariaLabel={el.title}>
                        {el.title}
                      </AppLink>
                    )}
                  </li>
                ))}
              </ul>
              <div className={styles.AppHeader__Resp__Wrapper__Content__Contact}>
                {burgerContact?.map((el, index) => (
                  <AppLink
                    key={index}
                    href={el.page_slug}
                    onClick={hideMenu}
                    ariaLabel={el.title}
                    title={el.title}>
                    {el.title}
                  </AppLink>
                ))}
              </div>
            </nav>
          </div>
        </div>
      </div>
    </>
  );
}

function ChevronDown() {
  return (
    <svg
      viewBox="0 0 24 24"
      width="24"
      height="24"
      stroke="currentColor"
      strokeWidth="2"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round">
      <polyline points="6 9 12 15 18 9"></polyline>
    </svg>
  );
}

function ChevronUp() {
  return (
    <svg
      viewBox="0 0 24 24"
      width="24"
      height="24"
      stroke="currentColor"
      strokeWidth="2"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round">
      <polyline points="18 15 12 9 6 15"></polyline>
    </svg>
  );
}
