import { useRef, useState } from 'react';

import AppLink from '../AppLink';
import { useOutsideClick } from '../../hooks/outsideClick';

import AppHeaderSubmenu from './AppHeaderSubmenu';
import styles from './AppHeaderNav.module.scss';

export default function AppHeaderNav({ headerMenu, activeMenu }) {
  const submenuRef = useRef(null);
  const [openSubmenu, setOpenSubmenu] = useState(false);
  const [activeMenuUrl, setActiveMenuUrl] = useState(null);

  const handleMenuClick = () => {
    setOpenSubmenu(!openSubmenu);
  };

  useOutsideClick(submenuRef, () => {
    setOpenSubmenu(false);
  });

  return (
    <nav className={styles.AppHeaderNav} ref={submenuRef}>
      <ul
        className={styles.AppHeaderNav__Menu}
        itemScope
        itemType="http://schema.org/SiteNavigationElement">
        {headerMenu?.map((el, index) => (
          <li
            onMouseLeave={() => {
              handleMenuClick();
              setActiveMenuUrl(el.item_id);
            }}
            onMouseEnter={() => {
              handleMenuClick();
              setActiveMenuUrl(el.item_id);
            }}
            itemProp="name"
            key={index}
            className={`${styles.AppHeaderNav__MenuItem} ${
              el.page_slug === activeMenu ? styles.AppHeaderNav__Active : ''
            }`}>
            {el.children.length > 0 ? (
              <>
                <span
                  className={`${styles.AppHeaderNav__MenuItem} ${
                    (activeMenuUrl === el.item_id && openSubmenu) || el.page_slug === activeMenu
                      ? styles.AppHeaderNav__Active
                      : ''
                  }`}>
                  {el.page_slug ? (
                    <AppLink
                      itemProp="url"
                      ariaLabel={el.title}
                      title={el.title}
                      href={el.redirect || el.page_slug}>
                      {el.title}
                    </AppLink>
                  ) : (
                    <span>{el.title}</span>
                  )}
                  <span className={styles.AppHeaderNav__Icon}>
                    {activeMenuUrl === el.item_id && openSubmenu ? <ChevronUp /> : <ChevronDown />}
                  </span>
                </span>

                {activeMenuUrl === el.item_id && openSubmenu && (
                  <AppHeaderSubmenu items={el.children} handleMenuClick={handleMenuClick} />
                )}
              </>
            ) : (
              <AppLink
                itemProp="url"
                ariaLabel={el.title}
                title={el.title}
                href={el.redirect || el.page_slug}>
                {el.title}
              </AppLink>
            )}
          </li>
        ))}
      </ul>
    </nav>
  );
}

function ChevronDown() {
  return (
    <svg
      viewBox="0 0 24 24"
      width="24"
      height="24"
      stroke="currentColor"
      strokeWidth="2"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round">
      <polyline points="6 9 12 15 18 9"></polyline>
    </svg>
  );
}

function ChevronUp() {
  return (
    <svg
      viewBox="0 0 24 24"
      width="24"
      height="24"
      stroke="currentColor"
      strokeWidth="2"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round">
      <polyline points="18 15 12 9 6 15"></polyline>
    </svg>
  );
}
