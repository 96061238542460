import Link from 'next/link';
import { useRouter } from 'next/router';

import { normalizeServerUrl } from '../utils/url';

export default function AppLink({ href, title, children, ariaLabel, target = '_blank', ...rest }) {
  const router = useRouter();
  const external = href?.includes(':');

  if (external) {
    return (
      <a
        title={title}
        href={href}
        aria-label={ariaLabel ? ariaLabel : 'link'}
        {...rest}
        target={target}
        referrerPolicy="no-referrer">
        {children}
      </a>
    );
  }

  return (
    <Link href={normalizeServerUrl(href)} locale={router.locale}>
      <a title={title} aria-label={ariaLabel ? ariaLabel : 'link'} {...rest}>
        {children}
      </a>
    </Link>
  );
}
