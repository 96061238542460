import { ToastContainer } from 'react-toastify';

import AppHeader from '../components/AppHeader/AppHeader';
import AppFooter from '../components/AppFooter/AppFooter';

export default function Layout({ children, localesUrls }) {
  return (
    <>
      <ToastContainer
        style={{ zIndex: 100000 }}
        position="top-right"
        autoClose={3000}
        closeButton={false}
        hideProgressBar={true}
        closeOnClick={true}
        pauseOnHover={false}
        draggable={false}
        progress={null}
        newestOnTop={true}
      />
      <AppHeader localesUrls={localesUrls}/>
      <main>{children}</main>
      <AppFooter />
    </>
  );
}
