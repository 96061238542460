import { useRouter } from 'next/router';

import { useAppStateContext } from './context';

export const useTranslation = () => {
  const { locales, defaultLocale, ...nextRouter } = useRouter();

  const { languages } = useAppStateContext().context;

  const locale = locales.includes(nextRouter.locale || 'en') ? nextRouter.locale : defaultLocale;

  return {
    translate: (term) => {

      const translation = languages?.data?.find((item) => item.keyword === term)?.value;
      return Boolean(translation) ? translation : `${locale}_${term}`;
    },
  };
};
